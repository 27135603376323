import 'src/styles/pages/lp.scss'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useState } from 'react'
import { CarouselBanner } from 'src/components/sections/CarouselBanner'
import Newsletter from 'src/components/sections/Newsletter'
import StockAvailable from 'src/components/common/StockAvailable/StockAvailable'
import ProductShelf from 'src/components/sections/ProductShelf'
import { useCmsLpImbatiblesQuery } from 'src/components/hooks/useCmsLpImbatiblesQuery'
import { useSession } from 'src/sdk/session'

interface Props {
  location: {
    pathname: string
  }
}

function Page({ location }: Props) {
  const { locale } = useSession()
  const {
    site,
    bannerImages,
    collectionShelfOne,
    collectionShelfTwo,
    collectionShelfThree,
    collectionShelfFour,
  } = useCmsLpImbatiblesQuery()

  const title: string = site?.title ?? ''
  const description = site?.description ?? ''

  const [hasProducts, setHasProducts] = useState<boolean[]>([])

  const dataShelfs = [
    collectionShelfOne,
    collectionShelfTwo,
    collectionShelfThree,
    collectionShelfFour,
  ]

  return (
    <>
      {/* SEO */}
      <GatsbySeo
        title={title}
        titleTemplate={site?.titleTemplate ?? ''}
        description={description}
        canonical={location.pathname}
        language={locale}
        openGraph={{
          type: 'website',
          title,
          description,
        }}
      />

      <CarouselBanner data-testid="Carousel" allItems={bannerImages} />

      {dataShelfs.map((shelf, index) => (
        <ProductShelf
          {...shelf}
          hasProducts={hasProducts}
          setHasProducts={setHasProducts}
          key={index}
        />
      ))}

      {!hasProducts.filter((e) => e === true).length && (
        <div className="no-active-promotion">
          No hay promociones activas en el momento.
        </div>
      )}

      <div className="lp__newsletter">
        <StockAvailable icon />
        <Newsletter
          title="Recibí promociones y novedades"
          subtitle="Ingresá tu correo electrónico"
        />
      </div>
    </>
  )
}

export default Page
